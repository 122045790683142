import Submission, { SubmissionConfig } from "@/components/Submission.tsx";

const contactConfig: SubmissionConfig = {
  title: "Contact Us",
  submitLabel: "Send Message",
  successMessage: "Thank you for your message. We'll get back to you soon!",
  storageDir: "contact",
  fields: [
    {
      name: "name",
      label: "Name",
      type: "text",
      required: true
    },
    {
      name: "email",
      label: "Email", 
      type: "text",
      required: true
    },
    {
      name: "message",
      label: "Message",
      type: "textarea",
      required: true,
      rows: 4
    }
  ]
};

export function ContactForm() {
  return <Submission config={contactConfig} />;
}
